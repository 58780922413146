// InvoiceForm.js
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, collection, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CustomerList from './CustomerList';
import ServiceList from './ServiceList';
import mixpanel from 'mixpanel-browser';
import useCurrentUser from '../../Authentication/currentUser';

export default function InvoiceForm({ invoiceData, setInvoiceData, currentUser, isProUser, setShowProModal }) {
  const navigate = useNavigate();
  const { username } = useCurrentUser();
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [currentSection, setCurrentSection] = useState(1);

  useEffect(() => {
    const fetchServices = async () => {
      if (currentUser) {
        const firestore = getFirestore();
        const profileRef = doc(firestore, 'profiles', currentUser.uid);
        const profileSnapshot = await getDoc(profileRef);
        if (profileSnapshot.exists()) {
          const profileData = profileSnapshot.data();
          setServices(profileData.services || []);
        }
      }
    };
    fetchServices();
  }, [currentUser]);

  useEffect(() => {
    const fetchClients = async () => {
      if (currentUser) {
        const firestore = getFirestore();
        const clientsRef = collection(firestore, 'users', currentUser.uid, 'clients');
        const unsubscribe = onSnapshot(clientsRef, snapshot => {
          const clientsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setCustomers(clientsData);
        });
        return () => unsubscribe();
      }
    };
    fetchClients();
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData(prev => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    setInvoiceData(prev => ({ ...prev, dueDate: date }));
  };

  const handleServiceSelect = (service) => {
    setInvoiceData(prev => ({
      ...prev,
      gig: service.name,
      price: service.price,
      currency: service.currency
    }));
  };

  const handleCustomerSelect = (customer) => {
    setInvoiceData(prev => ({
      ...prev,
      customer: customer.name,
      userEmail: customer.email,
      customEmailSubject: `Invoice for ${customer.name}`,
      customEmailContent: `Dear ${customer.name},\n\nPlease find attached the invoice for ${prev.gig}.\n\nInvoice Details:\nGig: ${prev.gig}\nAmount: ${prev.currency} ${prev.price}\nDue Date: ${prev.dueDate.toLocaleDateString()}\n\nThank you,\n${username}`
    }));
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    mixpanel.track("Invoice Generation Initiated");

    if (currentUser && currentUser.uid) {
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().stripeAccountId) {
        if (currentUser.emailVerified) {
          const formattedDueDate = invoiceData.dueDate.toISOString().split('T')[0];
          try {
            const newInvoiceData = {
              ...invoiceData,
              price: parseFloat(invoiceData.price) || 0,
              due_date: formattedDueDate,
              userId: currentUser.uid,
              freelancerEmail: currentUser.email,
              created: serverTimestamp(),
              connectedAccountId: userDoc.data().stripeAccountId,
            };

            const docRef = await addDoc(collection(firestore, 'invoices'), newInvoiceData);
            console.log("Document written with ID:", docRef.id);
            mixpanel.track("Invoice Document Created", { "Document ID": docRef.id });

            const paymentLink = `https://klorah.app/payment/${docRef.id}`;
            const updatedInvoiceData = { ...newInvoiceData, payment_link: paymentLink };

            if (invoiceData.userEmail) {
              console.log("Sending invoice email with data:", {
                to: invoiceData.userEmail,
                invoice: { id: docRef.id, ...updatedInvoiceData },
                customer_name: invoiceData.customer,
                customEmailSubject: invoiceData.customEmailSubject,
                customEmailContent: invoiceData.customEmailContent.replace(/\n/g, '<br>'),
              });

              const response = await fetch('https://klorah-fast-server.onrender.com/api/send-invoice', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  to: invoiceData.userEmail,
                  invoice: { id: docRef.id, ...updatedInvoiceData },
                  customer_name: invoiceData.customer,
                  customEmailSubject: invoiceData.customEmailSubject,
                  customEmailContent: invoiceData.customEmailContent.replace(/\n/g, '<br>'),
                }),
              });

              const result = await response.json();
              if (!response.ok) {
                throw new Error(result.detail || "Failed to send invoice email");
              }
            }

            navigate(`/preview-invoice/${docRef.id}`);
          } catch (error) {
            console.error("Error adding document:", error);
            mixpanel.track("Invoice Document Creation Error", { "Error": error.toString() });
            setError('Failed to create invoice. Please try again.');
          }
        } else {
          setError('You must verify your email to create invoices.');
          mixpanel.track("Invoice Generation Error", { "Error": 'User email is not verified.' });
        }
      } else {
        setError('You must have a connected Stripe account to create invoices.');
        mixpanel.track("Invoice Generation Error", { "Error": 'User does not have a connected Stripe account.' });
      }
    } else {
      setError('You must be logged in to create invoices.');
      mixpanel.track("Invoice Generation Error", { "Error": 'No user is logged in.' });
    }
    setIsLoading(false);
  };

  const handleNext = (e) => {
    e.preventDefault(); // Prevent form submission
    setCurrentSection(currentSection + 1);
  };

  const currencies = ['USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'HKD', 'NZD'];

  const renderSection = () => {
    switch(currentSection) {
      case 1:
        return (
          <>
            <div>
              <label htmlFor="gig" className="block text-lg font-medium mb-2">Gig</label>
              <input
                type="text"
                id="gig"
                name="gig"
                value={invoiceData.gig}
                onChange={handleInputChange}
                placeholder="Enter gig details"
                className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
              />
              <ServiceList services={services} onSelect={handleServiceSelect} />
            </div>

            <div className="mt-6">
              <label htmlFor="customer" className="block text-lg font-medium mb-2">Customer</label>
              <input
                type="text"
                id="customer"
                name="customer"
                value={invoiceData.customer}
                onChange={handleInputChange}
                placeholder="Enter customer name"
                className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
              />
              <CustomerList customers={customers} onSelect={handleCustomerSelect} />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="flex items-center gap-2">
              <div className="w-1/3 relative">
                <label htmlFor="currency" className="block text-lg font-medium mb-2">Currency</label>
                <button 
                  onClick={() => setDropdownVisible(!dropdownVisible)} 
                  className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 text-gray-700 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  {invoiceData.currency}
                </button>
                {dropdownVisible && (
                  <div className="absolute top-full mt-1 bg-white dark:bg-black border border-gray-300 dark:border-white rounded-lg max-h-36 overflow-y-scroll z-10">
                    {currencies.map((currency) => (
                      <button 
                        key={currency} 
                        onClick={() => { 
                          setInvoiceData(prev => ({ ...prev, currency })); 
                          setDropdownVisible(false); 
                        }} 
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-white/10"
                      >
                        {currency}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-2/3">
                <label htmlFor="price" className="block text-lg font-medium mb-2">Price</label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={invoiceData.price}
                  onChange={handleInputChange}
                  placeholder="Enter price"
                  className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
                />
              </div>
            </div>

            <div className="mt-6">
              <label htmlFor="dueDate" className="block text-lg font-medium mb-2">Due Date</label>
              <DatePicker
                selected={invoiceData.dueDate}
                onChange={handleDateChange}
                className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div>
              <label htmlFor="email" className="block text-lg font-medium mb-2">Customer Email (Optional)</label>
              <input
                type="email"
                id="email"
                name="userEmail"
                value={invoiceData.userEmail}
                onChange={handleInputChange}
                placeholder="Enter customer email"
                className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
              />
            </div>

            {invoiceData.userEmail && isProUser && (
              <>
                <div className="mt-6">
                  <label htmlFor="customEmailSubject" className="block text-lg font-medium mb-2">Custom Email Subject</label>
                  <input
                    type="text"
                    id="customEmailSubject"
                    name="customEmailSubject"
                    value={invoiceData.customEmailSubject}
                    onChange={handleInputChange}
                    placeholder="Enter email subject"
                    className="w-full h-12 border border-gray-300 dark:border-white rounded-lg px-4 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
                  />
                </div>

                <div className="mt-6">
                  <label htmlFor="customEmailContent" className="block text-lg font-medium mb-2">Custom Email Content</label>
                  <textarea
                    id="customEmailContent"
                    name="customEmailContent"
                    value={invoiceData.customEmailContent}
                    onChange={handleInputChange}
                    placeholder="Enter email content"
                    rows="4"
                    className="w-full border border-gray-300 dark:border-white rounded-lg px-4 py-2 focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-black dark:text-white"
                  />
                </div>
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-2xl bg-white dark:bg-black rounded-lg border border-gray-300 dark:border-white p-9">
      <h2 className="text-2xl font-semibold mb-5">Create Invoice</h2>
      
      <form onSubmit={(e) => e.preventDefault()}> {/* Prevent form submission on enter key */}
        {renderSection()}

        <div className="mt-8 flex justify-end">
          {currentSection > 1 && (
            <button
              type="button" // Explicitly set button type
              onClick={() => setCurrentSection(currentSection - 1)}
              className="px-4 py-2 bg-gray-200 text-black rounded-lg hover:bg-gray-300 dark:bg-black dark:text-white dark:hover:bg-white/10 dark:border dark:border-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-4"
            >
              Previous
            </button>
          )}
          {currentSection < 3 ? (
            <button
              type="button" // Explicitly set button type
              onClick={handleNext}
              className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 dark:bg-white dark:text-black dark:hover:bg-white/80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 min-w-[100px]"
            >
              Next
            </button>
          ) : (
            <button
              type="button" // Explicitly set button type
              onClick={handleGenerate}
              disabled={isLoading}
              className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 dark:bg-white dark:text-black dark:hover:bg-white/80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 min-w-[100px]"
            >
              {isLoading ? 'Generating...' : 'Generate Invoice'}
            </button>
          )}
        </div>
      </form>

      {error && <div className="mt-4 text-red-500 font-bold">{error}</div>}

      <div className="mt-4 text-sm text-gray-600 dark:text-white text-center">
        <p>Please note: Invoices are subject to a {isProUser ? '2.9%' : '3.3%'} processing fee.</p>
        {!isProUser && (
          <p className="mt-2">
            <button
              type="button" // Explicitly set button type
              onClick={() => setShowProModal(true)}
              className="text-green-500 hover:text-green-700"
            >
              Learn about Pro fees
            </button>
          </p>
        )}
      </div>
    </div>
  );
}